<template>
  <!-- <object :data="fileToView" type="application/pdf" width="100%" style="height: 50rem;"></object> -->
  <div
    id="viewer"
    ref="viewer"
    v-show="error == false"
    style="width=100%;height: 50rem;"
  ></div>
  <NoTableData v-if="error == true" :type="'renderFile'"></NoTableData>
</template>

<script>
import { mapGetters } from "vuex";
import WebViewer from "@pdftron/pdfjs-express-viewer";
import NoTableData from "./NoTableData";
export default {
  name: "Render file",
  components: {
    NoTableData,
  },
  data() {
    return {
      error: false,
      viewer: null,
    };
  },
  computed: {
    ...mapGetters(["fileToView"]),
  },
  mounted() {
    this.error = false;
    // const element = document.getElementById("viewer");
    console.log(WebViewer.getInstance(this.$refs.viewer));
    console.log(this.fileToView);
    WebViewer(
      {
        path: "/lib/WebViewer",
        licenseKey: process.env.VUE_APP_PDF_EXPRESS,
        disableLogs: true,
      },
      this.$refs.viewer
    ).then((instance) => {
      console.log(instance);
      this.viewer = instance;
      instance.UI.loadDocument(this.fileToView, { filename: "myfile.pdf" });
      // change to dark mode
      instance.UI.setTheme("dark");

      // remove left panel and left panel button from the DOM
      // instance.UI.disableElements(["leftPanel", "leftPanelButton"]);
      let isAdndroid = /(android)/i.test(navigator.userAgent);
      if (isAdndroid) {
        instance.UI.disableElements(["downloadButton"]);
      }
      instance.Core.documentViewer.addEventListener("documentLoaded", () => {
        // add a custom annotation
        // const rectangleAnnot =
        //   new instance.Core.Annotations.RectangleAnnotation();
        // rectangleAnnot.PageNumber = 1;
        // rectangleAnnot.X = 100;
        // rectangleAnnot.Y = 700;
        // rectangleAnnot.Width = 200;
        // rectangleAnnot.Height = 50;
        // instance.Core.annotationManager.addAnnotation(rectangleAnnot);
      });
      instance.UI.addEventListener("loaderror", () => {
        console.log("error");
        this.error = true;
      });
    });
  },
  unmounted() {
    this.viewer.UI.dispose();
    delete this.viewer.Core.documentViewer;
  },
};
</script>
